import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { fetchShops } from "redux/actions/shop";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import PayoffTable from "./components";
import { fetchPayoff } from "redux/actions/payoff";
import CastRes from "../../types/res/cast/CastRes";
import OrderRes from "../../types/res/order/OrderRes";
import { fetchCastCategory } from "redux/actions/castCategory";
import { Autocomplete } from "@material-ui/lab";
import {
  clearCastShift,
  fetchCastShift,
  fetchCastShiftStock,
} from "redux/actions/castShift";
import CastApi from "api/CastApi";

const Payoff = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const shops = useSelector((state) => state.shop);
  const castShifts = useSelector((state) => state.castShift);
  const payoff = useSelector((state) => state.payoff);
  const castCategories = useSelector((state) => state.castCategory);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [startDate, setStartDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [casts, setCasts] = useState<CastRes[]>([]);
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [selectCasts, setSelectCasts] = useState<CastRes[]>([]);
  const [filterCast, setFilterCast] = useState<CastRes[]>([]);
  const [filterPayoff, setFilterPayoff] = useState<OrderRes[]>([]);
  const [selectPayOff, setSelectPayOff] = useState<string[]>([]);
  const [selectCollectReceivable, setSelectCollectReceivable] = useState<
    string[]
  >([]);
  const [selectCastCategories, setSelectCastCategories] = useState<string[]>(
    []
  );
  const [requestStartDate, setRequestStartDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [requestEndDate, setRequestEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );

  const payoffStatus = [
    { label: "精算済み", value: true },
    { label: "未精算", value: false },
  ];
  const collectReceivableStatus = [
    { label: "回収済み", value: true },
    { label: "未回収", value: false },
  ];

  useEffect(() => {
    setSelectPayOff([...payoffStatus.map((p) => String(p.value)), "すべて"]);
    setSelectCollectReceivable([
      ...collectReceivableStatus.map((c) => String(c.value)),
      "すべて",
    ]);
  }, []);

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  useEffect(() => {
    const shiftCasts = castShifts
      .map((castShift) => castShift.cast)
      .filter(Boolean);
    setCasts((prev) =>
      Array.from(
        new Map(
          [...prev, ...shiftCasts].map((cast) => [cast.castId, cast])
        ).values()
      )
    );
    setFilterCast((prev) =>
      Array.from(
        new Map(
          [...prev, ...shiftCasts].map((cast) => [cast.castId, cast])
        ).values()
      )
    );
    setSelectCasts((prev) =>
      Array.from(
        new Map(
          [...prev, ...shiftCasts].map((cast) => [cast.castId, cast])
        ).values()
      )
    );
  }, [castShifts]);

  useEffect(() => {
    setSelectCastCategories(
      castCategories
        .filter((castCategory) => castCategory.name.includes("在籍"))
        .map((castCategory) => String(castCategory.castCategoryId))
    );
  }, [castCategories]);

  useEffect(() => {
    const filter = payoff.filter((p) => {
      const payoffCondition = selectPayOff.includes(String(p.payoff));
      const collectReceivableCondition = selectCollectReceivable.includes(
        String(p.collectReceivable)
      );
      const shopCondition = selectShops.includes(String(p.shopId));
      return payoffCondition && collectReceivableCondition && shopCondition;
    });
    setFilterPayoff(filter);
  }, [payoff, selectPayOff, selectCollectReceivable, selectShops]);

  useEffect(() => {
    dispatch(fetchShops(companyId));
    const apiCall = async () => {
      const result = await fetchCastCategory(companyId)(dispatch);
      if (result) {
        const castCategoryId = result.find((r) =>
          r.name.includes("在籍")
        )?.castCategoryId;
        const castRes = await CastApi.findAllWithPagination(companyId, {
          limit: 100,
          offset: 0,
          castCategoryIds: castCategoryId ? [castCategoryId] : undefined,
        });
        setCasts(castRes);
      }
    };
    apiCall();
  }, [companyId]);

  useEffect(() => {
    if (!companyId || !requestStartDate || !requestEndDate) return;
    dispatch(
      fetchPayoff(
        companyId,
        DateTime.fromJSDate(requestStartDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(requestEndDate).toFormat(FORMAT_TYPE.YEAR_DAY)
      )
    );
    dispatch(clearCastShift());
    // 日付の差分を計算
    const diffInDays = Math.ceil(
      (requestEndDate.getTime() - requestStartDate.getTime()) /
        (1000 * 60 * 60 * 24)
    );

    // 7日以下の場合は単一リクエスト
    if (diffInDays <= 7) {
      dispatch(
        fetchCastShift(
          companyId,
          DateTime.fromJSDate(requestStartDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          DateTime.fromJSDate(requestEndDate).toFormat(FORMAT_TYPE.YEAR_DAY)
        )
      );
      return;
    }

    // 7日ごとに区切って順次リクエストを行う関数
    const fetchDataSequentially = async () => {
      let currentStartDate = new Date(requestStartDate);

      while (currentStartDate < requestEndDate) {
        // 現在の開始日から7日後を計算
        let currentEndDate = new Date(currentStartDate);
        currentEndDate.setDate(currentEndDate.getDate() + 6); // 6日追加（開始日を含めると7日間）

        // 最終日がrequestEndDateを超えないようにする
        if (currentEndDate > requestEndDate) {
          currentEndDate = new Date(requestEndDate);
        }

        // 順次リクエストを行う（awaitで待機）
        await dispatch(
          fetchCastShiftStock(
            companyId,
            DateTime.fromJSDate(currentStartDate).toFormat(
              FORMAT_TYPE.YEAR_DAY
            ),
            DateTime.fromJSDate(currentEndDate).toFormat(FORMAT_TYPE.YEAR_DAY)
          )
        );

        // 次の期間の開始日を設定（現在の終了日の翌日）
        currentStartDate = new Date(currentEndDate);
        currentStartDate.setDate(currentStartDate.getDate() + 1);
      }
    };

    fetchDataSequentially();
  }, [dispatch, companyId, requestStartDate, requestEndDate]);

  return (
    <Box display="flex" flexDirection="column">
      <Box margin={2} display="flex">
        <TextField
          type="date"
          label="開始日"
          style={{ margin: "10px", width: "200px" }}
          value={DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
          onChange={(event) => {
            setStartDate(
              DateTime.fromISO(event.target.value as string).toJSDate()
            );
          }}
          onBlur={(event) => {
            const dateDiff = DateTime.fromJSDate(endDate).diff(
              DateTime.fromISO(event.target.value as string),
              "days"
            ).days;
            if (dateDiff > 31) {
              setStartDate(requestStartDate);
              return alert("期間は31日以内に設定してください。");
            }
            setRequestStartDate(
              DateTime.fromISO(event.target.value as string).toJSDate()
            );
          }}
        />
        <TextField
          type="date"
          label="終了日"
          style={{ margin: "10px", width: "200px" }}
          value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
          onChange={(event) => {
            setEndDate(
              DateTime.fromISO(event.target.value as string).toJSDate()
            );
          }}
          onBlur={(event) => {
            const dateDiff = DateTime.fromISO(
              event.target.value as string
            ).diff(DateTime.fromJSDate(startDate), "days").days;
            if (dateDiff > 31) {
              setEndDate(requestEndDate);
              return alert("期間は31日以内に設定してください。");
            }
            setRequestEndDate(
              DateTime.fromISO(event.target.value as string).toJSDate()
            );
          }}
        />
        <Box marginTop={"10px"}>
          <InputLabel id="select-multiple-shop">店舗</InputLabel>
          <Select
            multiple
            value={selectShops}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectShops((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...shops.map((shop) => String(shop.shopId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length === shops.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...shops.map((shop) => String(shop.shopId)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= shops.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-shop" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return shops
                  .filter((shop) =>
                    (selected as string[]).includes(String(shop.shopId))
                  )
                  .map((shop) => shop.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectShops.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {shops.map((shop) => (
              <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                <Checkbox
                  checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                />
                <ListItemText primary={shop.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <Autocomplete
            multiple
            renderInput={(params) => (
              <TextField {...params} label="キャスト名" />
            )}
            getOptionLabel={(cast) => cast?.displayName}
            options={casts}
            filterOptions={(options, state) =>
              options.filter((option) =>
                option.displayName.includes(state.inputValue)
              )
            }
            style={{ width: "400px", marginRight: "10px" }}
            value={selectCasts}
            limitTags={2}
            getLimitTagsText={(more) => {
              return <Typography>+{more}</Typography>;
            }}
            onInputChange={async (event, value) => {
              event.persist();
              const c = await CastApi.findAllWithPagination(companyId, {
                limit: 100,
                offset: 0,
                displayName: value,
                castCategoryIds:
                  selectCastCategories.includes("すべて") ||
                  selectCastCategories.length === 0
                    ? undefined
                    : selectCastCategories.map(Number),
              });
              setCasts((prev) =>
                Array.from(
                  new Map(
                    [...prev, ...c].map((cast) => [cast.castId, cast])
                  ).values()
                )
              );
            }}
            ListboxProps={{
              onScroll: async (event: React.SyntheticEvent) => {
                const listBoxNode = event.currentTarget;
                if (
                  listBoxNode.scrollTop + listBoxNode.clientHeight >
                  listBoxNode.scrollHeight - 1
                ) {
                  const offset = Math.trunc(casts.length / 100);
                  const c = await CastApi.findAllWithPagination(companyId, {
                    limit: 100,
                    offset,
                    castCategoryIds:
                      selectCastCategories.includes("すべて") ||
                      selectCastCategories.length === 0
                        ? undefined
                        : selectCastCategories.map(Number),
                  });
                  setCasts((prev) =>
                    Array.from(
                      new Map(
                        [...prev, ...c].map((cast) => [cast.castId, cast])
                      ).values()
                    )
                  );
                }
              },
            }}
            onChange={(event, value) => {
              setSelectCasts(value);
              setFilterCast(value);
            }}
          />
        </Box>
        <Box marginTop={"10px"}>
          <InputLabel id="select-multiple-class">キャスト区分</InputLabel>
          <Select
            multiple
            value={selectCastCategories}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectCastCategories((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...castCategories.map((castCategory) =>
                      String(castCategory.castCategoryId)
                    ),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length ===
                    castCategories.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...castCategories.map((castCategory) =>
                      String(castCategory.castCategoryId)
                    ),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <=
                  castCategories.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-castCategory" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return castCategories
                  .filter((castCategory) =>
                    (selected as string[]).includes(
                      String(castCategory.castCategoryId)
                    )
                  )
                  .map((castCategory) => castCategory.name)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectCastCategories.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {castCategories.map((castCategory) => (
              <MenuItem
                key={castCategory.castCategoryId}
                value={String(castCategory.castCategoryId)}
              >
                <Checkbox
                  checked={
                    selectCastCategories.indexOf(
                      String(castCategory.castCategoryId)
                    ) !== -1
                  }
                />
                <ListItemText primary={castCategory.name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box marginTop={"10px"}>
          <InputLabel id="select-multiple-cast">回収</InputLabel>
          <Select
            multiple
            value={selectCollectReceivable}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectCollectReceivable((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...collectReceivableStatus.map((c) => String(c.value)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length ===
                    collectReceivableStatus.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...collectReceivableStatus.map((c) => String(c.value)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <=
                  collectReceivableStatus.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-collectReceivable" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return collectReceivableStatus
                  .filter((c) =>
                    (selected as string[]).includes(String(c.value))
                  )
                  .map((c) => c.label)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectCollectReceivable.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {collectReceivableStatus.map((c) => (
              <MenuItem key={c.label} value={String(c.value)}>
                <Checkbox
                  checked={
                    selectCollectReceivable.indexOf(String(c.value)) !== -1
                  }
                />
                <ListItemText primary={c.label} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box marginTop={"10px"}>
          <InputLabel id="select-multiple-payoff">精算</InputLabel>
          <Select
            multiple
            value={selectPayOff}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSelectPayOff((prev: string[]) => {
                if (
                  prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [];
                } else if (
                  !prev.includes("すべて") &&
                  (event.target.value as string[]).indexOf("すべて") !== -1
                ) {
                  return [
                    ...payoffStatus.map((c) => String(c.value)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length ===
                    payoffStatus.length &&
                  (event.target.value as string[]).indexOf("すべて") === -1
                ) {
                  return [
                    ...payoffStatus.map((c) => String(c.value)),
                    "すべて",
                  ];
                } else if (
                  (event.target.value as string[]).length <= payoffStatus.length
                ) {
                  return (event.target.value as string[]).filter(
                    (name) => name !== "すべて"
                  );
                } else {
                  return event.target.value as string[];
                }
              });
            }}
            input={<Input id="select-multiple-collectReceivable" />}
            style={{ width: "200px", marginRight: "10px" }}
            renderValue={(selected) => {
              if ((selected as string[]).includes("すべて")) {
                return "すべて";
              } else {
                return payoffStatus
                  .filter((c) =>
                    (selected as string[]).includes(String(c.value))
                  )
                  .map((c) => c.label)
                  .join(", ");
              }
            }}
          >
            <MenuItem key={"すべて"} value={"すべて"}>
              <Checkbox
                name="all"
                checked={selectPayOff.indexOf("すべて") > -1}
              />
              <ListItemText primary={"すべて"} />
            </MenuItem>
            {payoffStatus.map((c) => (
              <MenuItem key={c.label} value={String(c.value)}>
                <Checkbox
                  checked={selectPayOff.indexOf(String(c.value)) !== -1}
                />
                <ListItemText primary={c.label} />
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box margin={2}>
        {filterCast.map((cast) => (
          <PayoffTable
            startDate={startDate}
            endDate={endDate}
            cast={cast}
            key={cast.castId}
            orders={filterPayoff.filter((order) =>
              cast.castNames
                .map((castName) => castName.castNameId)
                .includes(order.castNameId || 0)
            )}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Payoff;
