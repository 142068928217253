import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import MarketingMailApi from "api/MarketingMailApi";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import toastActions, { ToastType } from "redux/actions/toast";
import StaffRole from "types/enum/StaffRole";
import BulkSendMarketingMailReq from "types/req/marketingMail/BulkSendMarketingMailReq";
import MarketingMailRes from "types/res/marketingMail/MarketingMailRes";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import EnumUtils from "utils/EnumUtils";

const MarketingMail = () => {
  const dispatch = useDispatch();
  const [emails, setEmails] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [sendMarketingMails, setSendMarketingMails] = useState<
    MarketingMailRes[]
  >([]);
  const [month, setMonth] = useState(DateTime.now().toFormat("yyyy-MM"));
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const staffRole = useSelector((state) => state.account.staff.role);

  const selectOptions = [
    {
      label: "タステック: 現場満足度120%無料の顧客管理システム",
      value: "MarketingMail1",
    },
    {
      label: "業界初！無料で使える「タステック」で売上分析と顧客管理を一新！",
      value: "MarketingMail2",
    },
    {
      label:
        "高機能なのにコストゼロ！「タステック」で業務効率と売上アップを実現",
      value: "MarketingMail3",
    },
    {
      label:
        "今だけ無料提供中！高度な分析機能搭載の顧客管理システム「タステック」",
      value: "MarketingMail4",
    },
    {
      label: "直感操作でラクラク管理！「タステック」で未来のビジネスを始めよう",
      value: "MarketingMail5",
    },
    {
      label:
        "売上解析力は有料ツール以上！無料の「タステック」で差をつけるチャンス",
      value: "MarketingMail6",
    },
  ];

  useEffect(() => {
    const apiCall = async () => {
      const res = await MarketingMailApi.findAll(month);
      setSendMarketingMails(res);
    };
    apiCall();
  }, [month]);

  if (EnumUtils.mapToEnum(StaffRole, staffRole) !== StaffRole.notelMaster) {
    return <Redirect to="/" />;
  }

  const handleSend = async () => {
    setIsSending(true);
    const mailList = emails.split("\n").map((email) => email.trim());
    if (mailList.length > 500) {
      dispatch(
        toastActions.showToast({
          text: "500件以上のメールアドレスは送信できません。",
          type: ToastType.Failure,
        })
      );
      return;
    }
    try {
      await MarketingMailApi.bulkSend(
        new BulkSendMarketingMailReq({
          emails: mailList,
          template: selectedTemplate,
        })
      );
      dispatch(
        toastActions.showToast({
          text: "送信に成功しました",
          type: ToastType.Success,
        })
      );
    } catch (e: any) {
      dispatch(
        toastActions.showToast({
          text: e.message ?? "送信に失敗しました",
          type: ToastType.Failure,
        })
      );
    }
    setIsSending(false);
  };

  return (
    <Box padding={5}>
      <Typography variant="h4">営業メール一括送信</Typography>
      <Typography>500件まで入力可能です。</Typography>
      <InputLabel style={{ marginTop: "30px" }}>テンプレート</InputLabel>
      <Select
        value={selectedTemplate}
        onChange={(e) => setSelectedTemplate(e.target.value as string)}
        style={{ width: "100%", margin: "20px 0" }}
      >
        {selectOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <TextareaAutosize
        placeholder="メールアドレスを改行区切りで入力してください"
        rows={50}
        style={{ width: "100%", margin: "20px 0" }}
        onChange={(e) => setEmails(e.target.value)}
        value={emails}
      />
      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSend}
          disabled={isSending}
        >
          送信
        </Button>
      </Box>
      <Box marginTop={5}>
        <Typography variant="h4">送信履歴</Typography>
        <Box marginTop={2}>
          <TextField
            label="対象月"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          />
        </Box>
        <Box display="flex" flexDirection="column">
          {sendMarketingMails.map((sendMarketingMail) => (
            <Box
              key={sendMarketingMail.sendMarktingMailId}
              display="flex"
              marginTop={1}
            >
              <Typography>
                {DateTime.fromJSDate(sendMarketingMail.createdAt).toFormat(
                  FORMAT_TYPE.YEAR_DATE_TIME
                )}
                : {sendMarketingMail.email}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default MarketingMail;
