import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  Icon,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import { DateTime } from "luxon";
import React, { ChangeEvent, useEffect, useState } from "react";
import OrderResponseStatus from "types/enum/OrderResponseStatus";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PaymentType from "types/enum/PaymentType";
import { useDispatch, useSelector } from "react-redux";
import EnumUtils from "utils/EnumUtils";
import AreaRes from "types/res/area/AreaRes";
import HotelRes from "types/res/hotel/HotelRes";
import CourseRes from "types/res/course/CourseRes";
import AdditionalTimeRes from "types/res/additionalTime/AdditionalTimeRes";
import DiscountRes from "types/res/discount/DiscountRes";
import OptionRes from "types/res/option/OptionRes";
import CosplayRes from "types/res/cosplay/CosplayRes";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import OrderApi from "api/OrderApi";
import OrderRes from "types/res/order/OrderRes";
import CastNameRes from "types/res/castName/CastNameRes";
import OrderStatus from "types/enum/OrderStatus";
import NotelClassRes from "types/res/notelClass/NotelClassRes";
import TimeUtils from "utils/TimeUtils";
import MediumRes from "types/res/medium/MediumRes";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { fetchDriverShift } from "redux/actions/driverShift";
import { fetchCastShift } from "redux/actions/castShift";
import { Info } from "@material-ui/icons";

type Props = {
  onChangePrice(key: string): any;
  onChange(key: string): any;
  onChangeCheckBox(key: string): any;
  onChangeAdditionalTimeOrder(index: number, key: string, value: any): any;
  addAdditionalTimeOrder(): any;
  deleteAdditionalTimeOrder(index: number): any;
  formData: any;
  startDate: string;
  endDate: string;
  oldOrder: OrderRes;
};
const OrderInfo: React.FC<Props> = ({
  onChangePrice,
  onChange,
  formData,
  onChangeCheckBox,
  onChangeAdditionalTimeOrder,
  addAdditionalTimeOrder,
  deleteAdditionalTimeOrder,
  startDate,
  endDate,
  oldOrder,
}) => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const shops = useSelector((state) => state.shop);
  const castNames = useSelector((state) => state.castName);
  const castShifts = useSelector((state) => state.castShift);
  const courses = useSelector((state) => state.course);
  const additionalTimes = useSelector((state) => state.additionalTime);
  const discounts = useSelector((state) => state.discount);
  const options = useSelector((state) => state.option);
  const cosplays = useSelector((state) => state.cosplay);
  const areas = useSelector((state) => state.area);
  const hotels = useSelector((state) => state.hotel);
  const nominations = useSelector((state) => state.nomination);
  const notelClasses = useSelector((state) => state.notelClass);
  const media = useSelector((state) => state.medium);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [filterCastNames, setFilterCastNames] = useState<CastNameRes[]>([]);
  const [filterNotelClasses, setFilterNotelClasses] = useState<NotelClassRes[]>(
    []
  );
  const [filterCourses, setFilterCourses] = useState<CourseRes[]>([]);
  const [filterAdditionalTimes, setFilterAdditionalTimes] = useState<
    AdditionalTimeRes[]
  >([]);
  const [filterDiscounts, setFilterDiscounts] = useState<DiscountRes[]>([]);
  const [filterOptions, setFilterOptions] = useState<OptionRes[]>([]);
  const [filterCosplays, setFilterCosplays] = useState<CosplayRes[]>([]);
  const [filterHotels, setFilterHotels] = useState<HotelRes[]>([]);
  const [filterAreas, setFilterAreas] = useState<AreaRes[]>([]);
  const [filterMedia, setFilterMedia] = useState<MediumRes[]>([]);
  const [hours, setHours] = useState<string[]>([]);
  const [minOpeningTime, setMinOpeningTime] = useState(0);
  const [maxClosingTime, setMaxClosingTime] = useState(0);
  const [selectArea, setSelectArea] = useState<AreaRes>();
  const [selectHotel, setSelectHotel] = useState<HotelRes>();
  const [selectCastName, setSelectCastName] = useState<CastNameRes>();
  const [reOrder, setReOrder] = useState<OrderRes>();
  const [isLoading, setIsLoading] = useState(false);
  const minutes = [
    "00",
    "05",
    "10",
    "15",
    "20",
    "25",
    "30",
    "35",
    "40",
    "45",
    "50",
    "55",
  ];
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  const highlightNgAreaStyles = makeStyles(() => ({
    inputRoot: {
      color: selectCastName?.cast?.ngAreas
        ?.map((area) => area.areaId)
        ?.includes(formData?.areaId)
        ? "red"
        : "inherit",
    },
  }));
  const highlightNgAreaClasses = highlightNgAreaStyles();
  const highlightNgGuestStyles = makeStyles(() => ({
    inputRoot: {
      color: selectCastName?.cast?.ngGuests
        ?.map((guest) => guest.guestId)
        ?.includes(formData?.guestId)
        ? "red"
        : "inherit",
    },
  }));
  const highlightNgGuestClasses = highlightNgGuestStyles();
  useEffect(() => {
    if (!maxClosingTime && !minOpeningTime) return;
    const timeDiff = TimeUtils.isInAllDays(shops)
      ? maxClosingTime - minOpeningTime
      : maxClosingTime - minOpeningTime + 24;
    const arrayHours = [...Array(timeDiff).keys()].map((a) =>
      String(a + minOpeningTime).padStart(2, "0")
    );
    setHours(arrayHours);
  }, [minOpeningTime, maxClosingTime]);

  useEffect(() => {
    const apiCall = async () => {
      setIsLoading(true);
      await dispatch(fetchDriverShift(companyId, startDate, endDate));
      await dispatch(fetchCastShift(companyId, startDate, endDate));
      setIsLoading(false);
    };
    apiCall();
  }, [startDate, endDate]);

  useEffect(() => {
    if (!shops.length) return;
    setMinOpeningTime(
      Number(
        shops.reduce(
          (a, b) =>
            a < Number(b.openingTime.split(":")[0])
              ? a
              : Number(b.openingTime.split(":")[0]),
          24
        )
      )
    );
    setMaxClosingTime(TimeUtils.maxClosingTimeInShop(shops));
  }, [shops]);

  useEffect(() => {
    setFilterAdditionalTimes(
      additionalTimes.filter(
        (additionalTime) =>
          (formData?.shopId
            ? additionalTime.shops.some(
                (shop) => shop.shopId === formData.shopId
              )
            : true) &&
          (formData?.notelClassId
            ? !!additionalTime.notelClasses.find(
                (notelClass) =>
                  notelClass.notelClassId === formData.notelClassId
              )
            : true)
      )
    );
    setFilterCosplays(
      cosplays.filter((cosplay) =>
        formData?.shopId
          ? cosplay.shops.some((shop) => shop.shopId === formData.shopId)
          : true
      )
    );
    setFilterDiscounts(
      discounts.filter((discount) =>
        formData?.shopId
          ? discount.shops.some((shop) => shop.shopId === formData.shopId)
          : true
      )
    );
    setFilterMedia(
      media.filter((media) =>
        formData?.shopId
          ? media.shops.some((shop) => shop.shopId === formData.shopId)
          : true
      )
    );
  }, [
    formData?.shopId,
    formData?.notelClassId,
    additionalTimes,
    cosplays,
    discounts,
    media,
  ]);

  useEffect(() => {
    setFilterCastNames(
      castShifts?.flatMap((castShift) =>
        castShift?.cast?.castNames?.filter((castName) => {
          const shopCondition = formData?.shopId
            ? castName.shopId === formData.shopId
            : true;
          const notelClassCondition = formData?.notelClassId
            ? castName.notelClassId === formData?.notelClassId
            : true;
          return shopCondition && notelClassCondition;
        })
      )
    );
    setSelectCastName((prev) => {
      if (
        prev?.shopId === formData.shopId &&
        prev?.notelClassId === formData.notelClassId
      ) {
        return prev;
      }
    });
  }, [formData?.notelClassId, formData?.shopId, castShifts]);

  useEffect(() => {
    if (
      !formData?.castName ||
      filterCastNames
        .map((castName) => castName.castNameId)
        .includes(formData?.castNameId)
    )
      return;
    setFilterCastNames((prev) => [...prev, formData?.castName]);
  }, [formData?.castName]);

  useEffect(() => {
    let filterOptions = options.filter((option) =>
      formData?.shopId
        ? option.shops.some((shop) => shop.shopId === formData.shopId)
        : true
    );
    const castName = castNames.find(
      (castName) => castName.castNameId === formData?.castNameId
    );
    if (castName) {
      filterOptions = castName.options;
      setSelectCastName(castName);
    }
    setFilterOptions(filterOptions);
  }, [formData?.castNameId, formData.shopId, castNames]);

  useEffect(() => {
    setFilterCourses(
      courses.filter(
        (course) =>
          (formData?.notelClassId
            ? course.notelClassId === formData?.notelClassId
            : true) &&
          (formData?.shopId ? course.shopId === formData?.shopId : true) &&
          (formData?.courseTime
            ? course.time === formData?.courseTime
            : true) &&
          (formData?.nominationId
            ? course.nominationId === formData?.nominationId
            : true)
      )
    );
  }, [
    formData.courseTime,
    formData.shopId,
    formData.nominationId,
    formData.notelClassId,
    courses,
  ]);

  useEffect(() => {
    if (formData.areaId) {
      setFilterHotels(
        hotels.filter((hotel) =>
          hotel.areas?.map((area) => area.areaId).includes(formData.areaId)
        )
      );
      setSelectArea(areas.find((area) => area.areaId === formData?.areaId));
    } else {
      setFilterHotels(hotels);
    }
    const hotel = hotels.find((hotel) => hotel.hotelId === formData.hotelId);
    if (hotel) {
      setSelectHotel(hotel);
    }
    if (!hotel || !hotel?.areas) {
      setFilterAreas(
        areas.filter((area) =>
          formData?.shopId
            ? area.shops.some((shop) => shop.shopId === formData.shopId)
            : true
        )
      );
    } else if (hotel?.areas.length === 1) {
      onChangePrice("areaId")(hotel?.areas[0].areaId);
      setSelectArea(hotel?.areas[0]);
      setFilterAreas(hotel.areas);
    } else if (hotel.areas.length > 1) {
      setFilterAreas(hotel?.areas);
    }
    onChangePrice("orderAddress2")(hotel?.address || formData?.orderAddress2);
  }, [formData.hotelId, formData.areaId, formData.shopId, hotels, areas]);

  useEffect(() => {
    const nomination = nominations.find(
      (nomination) => nomination.nominationId === formData.nominationId
    );
    onChangePrice("designateFee")(nomination?.totalFee || 0);
    onChangePrice("designateCastFee")(nomination?.castFee || 0);
    onChangePrice("designateShopFee")(nomination?.shopFee || 0);
  }, [formData.nominationId, nominations]);

  useEffect(() => {
    calcTotalTime(formData);
  }, [
    formData?.courseId,
    formData?.additionalTimeOrders,
    courses,
    additionalTimes,
  ]);

  useEffect(() => {
    calcAdditionalFee(
      formData?.additionalTimeOrders?.map((additionalTimeOrder: any) => ({
        additionalTime: additionalTimes.find(
          (additionalTime) =>
            additionalTime.additionalTimeId ===
            additionalTimeOrder.additionalTimeId
        ),
        count: Number(additionalTimeOrder.count || 0),
      }))
    );
  }, [formData?.additionalTimeOrders, additionalTimes]);

  useEffect(() => {
    if (
      PaymentType.creditCard ===
      EnumUtils.mapToEnum(PaymentType, formData?.paymentType)
    ) {
      const shop = shops.find((shop) => shop.shopId === formData?.shopId);
      const cardFee = Math.round(
        (formData?.subTotalFee || 0) * ((shop?.cardRate || 0) / 100)
      );
      onChangePrice("cardFee")(cardFee);
    } else {
      onChangePrice("cardFee")(null);
    }
  }, [
    formData?.paymentType,
    formData?.subTotalFee,
    formData?.shopId,
    formData?.orderId,
  ]);

  useEffect(() => {
    calcPlanOutTime(formData);
  }, [formData.planInTime, formData.totalTime]);

  useEffect(() => {
    calcActualEndTime(formData);
  }, [formData.actualInTime, formData.totalTime, oldOrder]);

  useEffect(() => {
    const course = courses.find(
      (course) => course.courseId === formData?.courseId
    );
    onChangePrice("courseFee")(course?.timeFee || 0);
    onChangePrice("courseCastFee")(course?.timeCastFee || 0);
    onChangePrice("courseShopFee")(course?.timeShopFee || 0);
    onChangePrice("timeFee")(course?.timeFee || 0);
    onChangePrice("timeCastFee")(course?.timeCastFee || 0);
    onChangePrice("timeShopFee")(course?.timeShopFee || 0);
    onChangePrice("hotelFee")(course?.hotelFee || 0);
    onChangePrice("hotelCastFee")(course?.hotelCastFee || 0);
    onChangePrice("hotelShopFee")(course?.hotelShopFee || 0);
    onChangePrice("welfareFee")(course?.welfareFee || 0);
  }, [formData.courseId, courses]);

  useEffect(() => {
    calcReturnTime(formData);
  }, [formData.areaId, formData.planOutTime]);

  useEffect(() => {
    if (!formData?.guestId || !formData?.castNameId) return;
    const fetch = async () => {
      setReOrder(
        await OrderApi.findReOrder(
          companyId,
          formData.guestId,
          formData.castNameId
        )
      );
    };
    fetch();
  }, [formData?.guestId, formData?.castNameId]);

  useEffect(() => {
    if (formData?.orderId || !reOrder?.orderId) return;
    const shop = shops.find((shop) => shop.shopId === formData?.shopId);
    if (!shop) {
      return onChangePrice("nominationId")(null);
    }
    const nominationId =
      reOrder?.shopId === formData?.shopId ? shop.nominationId : null;
    onChangePrice("nominationId")(nominationId);
  }, [formData?.shopId, reOrder]);

  useEffect(() => {
    if (!formData.castNameId || !selectCastName) {
      return setFilterNotelClasses(notelClasses);
    }
    const castName = castNames.find(
      (castName) => castName.castNameId === formData?.castNameId
    );
    if (!castName) return;
    onChangePrice("shopId")(castName.shopId);
    onChangePrice("notelClassId")(castName.notelClassId);
    setFilterNotelClasses(
      notelClasses.filter(
        (notelClass) => notelClass.notelClassId === castName.notelClassId
      )
    );
  }, [formData?.castNameId, notelClasses, selectCastName]);

  useEffect(() => {
    if (
      formData?.status &&
      formData?.actualInTime &&
      formData?.paymentType &&
      [OrderStatus.paid, OrderStatus.booking].includes(
        EnumUtils.mapToEnum(OrderStatus, formData.status) || OrderStatus.hold
      ) &&
      EnumUtils.mapToEnum(PaymentType, formData.paymentType) ===
        PaymentType.creditCard
    ) {
      onChangePrice("collectReceivable")(true);
    }
  }, [formData?.status, formData?.paymentType, formData?.actualInTime]);

  useEffect(() => {
    if (!formData?.departureHour || !formData?.departureMinute) {
      onChangePrice("departureTime")(null);
      return;
    }
    if (Number(formData.departureHour) > 24) {
      onChangePrice("departureTime")(
        DateTime.fromJSDate(formData.orderDate || new Date())
          .startOf("day")
          .plus({
            days: 1,
            hours: Number(formData.departureHour) - 24,
            minutes: Number(formData.departureMinute),
          })
          .toJSDate()
      );
    } else {
      onChangePrice("departureTime")(
        DateTime.fromJSDate(formData.orderDate || new Date())
          .startOf("day")
          .plus({
            hours: Number(formData.departureHour),
            minutes: Number(formData.departureMinute),
          })
          .toJSDate()
      );
    }
  }, [formData?.departureHour, formData?.departureMinute, formData?.orderDate]);

  useEffect(() => {
    if (!formData?.planInHour || !formData?.planInMinute) {
      onChangePrice("planInTime")(null);
      return;
    }
    if (Number(formData.planInHour) > 24) {
      onChangePrice("planInTime")(
        DateTime.fromJSDate(formData.orderDate || new Date())
          .startOf("day")
          .plus({
            days: 1,
            hours: Number(formData.planInHour) - 24,
            minutes: Number(formData.planInMinute),
          })
          .toJSDate()
      );
    } else {
      onChangePrice("planInTime")(
        DateTime.fromJSDate(formData.orderDate || new Date())
          .startOf("day")
          .plus({
            hours: Number(formData.planInHour),
            minutes: Number(formData.planInMinute),
          })
          .toJSDate()
      );
    }
  }, [formData?.planInHour, formData?.planInMinute, formData?.orderDate]);

  useEffect(() => {
    if (!formData?.actualInHour || !formData?.actualInMinute) {
      onChangePrice("actualInTime")(null);
      return;
    }
    if (Number(formData.actualInHour) > 24) {
      onChangePrice("actualInTime")(
        DateTime.fromJSDate(formData.orderDate || new Date())
          .startOf("day")
          .plus({
            days: 1,
            hours: Number(formData.actualInHour) - 24,
            minutes: Number(formData.actualInMinute),
          })
          .toJSDate()
      );
    } else {
      onChangePrice("actualInTime")(
        DateTime.fromJSDate(formData.orderDate || new Date())
          .startOf("day")
          .plus({
            hours: Number(formData.actualInHour),
            minutes: Number(formData.actualInMinute),
          })
          .toJSDate()
      );
    }
  }, [formData?.actualInHour, formData?.actualInMinute, formData?.orderDate]);

  useEffect(() => {
    if (!formData?.requestActualInHour || !formData?.requestActualInMinute) {
      onChangePrice("requestActualInTime")(null);
      return;
    }
    if (Number(formData.requestActualInHour) > 24) {
      onChangePrice("requestActualInTime")(
        DateTime.fromJSDate(formData.orderDate || new Date())
          .startOf("day")
          .plus({
            days: 1,
            hours: Number(formData.requestActualInHour) - 24,
            minutes: Number(formData.requestActualInMinute),
          })
          .toJSDate()
      );
    } else {
      onChangePrice("requestActualInTime")(
        DateTime.fromJSDate(formData.orderDate || new Date())
          .startOf("day")
          .plus({
            hours: Number(formData.requestActualInHour),
            minutes: Number(formData.requestActualInMinute),
          })
          .toJSDate()
      );
    }
  }, [
    formData?.requestActualInHour,
    formData?.requestActualInMinute,
    formData?.orderDate,
  ]);

  useEffect(() => {
    const medium = media.find(
      (medium) => medium.mediumId === formData.mediumId
    );
    onChangePrice("mediumFee")(medium?.totalFee || 0);
    onChangePrice("mediumCastFee")(medium?.castFee || 0);
    onChangePrice("mediumShopFee")(medium?.shopFee || 0);
  }, [formData.mediumId, media]);

  useEffect(() => {
    if (!selectHotel) return;
    const ho = hotels.find((hotel) => hotel.hotelId === selectHotel?.hotelId);
    if (ho?.isIncludeOrderPrice) {
      onChangePrice("hotelFee")(ho?.totalFee || 0);
      onChangePrice("hotelCastFee")(ho?.castFee || 0);
      onChangePrice("hotelShopFee")(ho?.shopFee || 0);
    }
  }, [selectHotel, hotels]);

  const calcTotalTime = (formData: any) => {
    const course = courses.find(
      (course) => course.courseId === formData?.courseId
    );
    if (!course) return;
    const additionalTime = formData.additionalTimeOrders?.reduce(
      (sum: number, additionalTimeOrder: any) => {
        const aT = additionalTimes.find(
          (addTime) =>
            addTime.additionalTimeId === additionalTimeOrder.additionalTimeId
        );
        return sum + (aT?.time || 0) * additionalTimeOrder.count;
      },
      0
    );
    const totalTime = (formData.courseTime || course.time) + additionalTime;
    onChangePrice("totalTime")(totalTime);
  };

  const calcPlanOutTime = (formData: any) => {
    if (!formData.planInTime || !formData.totalTime) {
      onChangePrice("planOutTime")(null);
      return;
    }
    const planOutTime = DateTime.fromJSDate(formData.planInTime)
      .plus({ minutes: formData.totalTime })
      .toJSDate();
    onChangePrice("planOutTime")(planOutTime);
  };

  const calcActualEndTime = (formData: any) => {
    if (!formData.actualInTime || !formData.totalTime) {
      onChangePrice("actualEndTime")(null);
      return;
    }
    if (
      oldOrder?.actualInTime?.getTime() !== formData?.actualInTime?.getTime() ||
      formData.totalTime !== oldOrder.totalTime
    ) {
      const actualEndTime = DateTime.fromJSDate(formData.actualInTime)
        .plus({ minutes: formData.totalTime })
        .toJSDate();
      onChangePrice("actualEndTime")(actualEndTime);
    }
  };

  const calcReturnTime = (formData: any) => {
    if (!formData.planOutTime || !formData.areaId) return;
    const area = areas.find((area) => area.areaId === formData.areaId);
    if (!area) return;
    const returnTime = DateTime.fromJSDate(formData.planOutTime)
      .plus({
        minutes: area.time,
      })
      .toJSDate();
    onChangePrice("returnTime")(returnTime);
  };

  const calcAdditionalFee = (
    additionalTimeOrders: { additionalTime: AdditionalTimeRes; count: number }[]
  ) => {
    onChangePrice("additionalFee")(
      additionalTimeOrders?.reduce(
        (sum, additionalTimeOrder) =>
          (additionalTimeOrder?.additionalTime?.totalFee || 0) *
            additionalTimeOrder.count +
          sum,
        0
      )
    );
    onChangePrice("additionalShopFee")(
      additionalTimeOrders?.reduce(
        (sum, additionalTimeOrder) =>
          (additionalTimeOrder?.additionalTime?.shopFee || 0) *
            additionalTimeOrder.count +
          sum,
        0
      )
    );
    onChangePrice("additionalCastFee")(
      additionalTimeOrders?.reduce(
        (sum, additionalTimeOrder) =>
          (additionalTimeOrder?.additionalTime?.castFee || 0) *
            additionalTimeOrder.count +
          sum,
        0
      )
    );
  };
  const castNameInfo = selectCastName ? (
    <Box display="flex" flexDirection="column">
      <Typography variant={"subtitle1"}>【キャスト備考】</Typography>
      <Typography style={{ whiteSpace: "pre-wrap" }}>
        {selectCastName?.cast?.remarks}
      </Typography>
      <Typography variant={"subtitle1"} style={{ marginTop: "12px" }}>
        【当日シフトキャストメモ】
      </Typography>
      <Typography>
        {castShifts?.find(
          (castShift) => castShift.castId === selectCastName?.castId
        )?.castMemo || ""}
      </Typography>
      <Typography variant={"subtitle1"} style={{ marginTop: "12px" }}>
        【当日シフトスタッフメモ】
      </Typography>
      <Typography>
        {castShifts?.find(
          (castShift) => castShift.castId === selectCastName?.castId
        )?.staffMemo || ""}
      </Typography>
    </Box>
  ) : (
    <Typography>キャストを選択してください</Typography>
  );

  const onClickAcceptCastRequestButton = () => {
    onChangePrice("actualEndTime")(
      formData?.requestActualEndTime ||
        DateTime.fromJSDate(
          formData?.actualInTime || formData?.requestActualInTime
        )
          .plus({ minutes: formData?.totalTime || 0 })
          .toJSDate()
    );
    onChangePrice("requestActualEndTime")(null);
    if (formData?.requestActualEndTime) {
      onChangePrice("responseStatus")("ok");
    }
    if (formData?.requestActualInTime) {
      onChangePrice("actualInTime")(formData?.requestActualInTime);
      onChangePrice("actualInHour")(formData?.requestActualInHour);
      onChangePrice("actualInMinute")(formData?.requestActualInMinute);
      onChangePrice("requestActualInTime")(null);
      onChangePrice("requestActualInHour")(null);
      onChangePrice("requestActualInMinute")(null);
    }
  };
  const onClickResetOrderInfoButton = () => {
    onChangePrice("shopId")(null);
    onChangePrice("castNameId")(null);
    onChangePrice("notelClassId")(null);
    onChangePrice("courseTime")(null);
    onChangePrice("nominationId")(null);
    onChangePrice("courseId")(null);
    onChangePrice("areaId")(null);
    onChangePrice("hotelId")(null);
    onChangePrice("orderAddress2")(null);
    onChangePrice("orderAddress")(null);
    onChangePrice("departureTime")(null);
    onChangePrice("departureHour")(null);
    onChangePrice("departureMinute")(null);
    onChangePrice("planInTime")(null);
    onChangePrice("planInHour")(null);
    onChangePrice("planInMinute")(null);
    onChangePrice("actualInTime")(null);
    onChangePrice("actualInHour")(null);
    onChangePrice("actualInMinute")(null);
    onChangePrice("requestActualInTime")(null);
    onChangePrice("requestActualInHour")(null);
    onChangePrice("requestActualInMinute")(null);
    onChangePrice("requestActualEndTime")(null);
    onChangePrice("actualEndTime")(null);
    onChangePrice("totalTime")(null);
    onChangePrice("returnTime")(null);
    onChangePrice("mediumId")(null);
    onChangePrice("planOutTime")(null);
    onChangePrice("responseStatus")(null);
    onChangePrice("memo")("");
    onChangePrice("questionnaire")("");
    onChangePrice("score")("");
    onChangePrice("options")([]);
    onChangePrice("discounts")([]);
    onChangePrice("cosplayId")(null);
    onChangePrice("additionalTimeOrders")([
      { additionalTimeId: null, count: 0 },
    ]);
    onChangePrice("optionFee")(0);
    onChangePrice("optionShopFee")(0);
    onChangePrice("optionCastFee")(0);
    onChangePrice("discountFee")(0);
    onChangePrice("discountShopFee")(0);
    onChangePrice("discountCastFee")(0);
    onChangePrice("areaFee")(0);
    onChangePrice("areaShopFee")(0);
    onChangePrice("areaCastFee")(0);
    setSelectArea(undefined);
    setSelectHotel(undefined);
    setSelectCastName(undefined);
  };

  if (isLoading) return <CircularProgress />;

  return (
    <>
      <Box>
        <Typography variant="h5" component="h5" style={{ marginBottom: 10 }}>
          受注情報
        </Typography>
        <Box display="flex" flexDirection="column">
          <TextField
            label="日付"
            type="date"
            size="small"
            variant="outlined"
            value={
              formData?.orderDate
                ? DateTime.fromJSDate(formData.orderDate).toFormat(
                    FORMAT_TYPE.YEAR_DAY
                  )
                : DateTimeUtils.toFormatAsLocalTimezone(
                    DateTime.local()
                      .minus({
                        hours: changeDate.hour,
                        minutes: changeDate.minute,
                      })
                      .toJSDate(),
                    FORMAT_TYPE.YEAR_DAY
                  )
            }
            onChange={onChange("orderDate")}
          />
          <Box display="flex" style={{ margin: 10 }} alignItems="center">
            <InputLabel htmlFor={"shop"}>店舗</InputLabel>
            <Select
              inputProps={{
                name: "shop",
                id: "shop",
              }}
              style={{ width: 100 }}
              value={formData?.shopId || ""}
              onChange={onChange("shopId")}
            >
              <option aria-label="None" value="" />
              {shops?.map((shop) => (
                <MenuItem value={shop.shopId} key={shop.shopId}>
                  {shop.name}
                </MenuItem>
              ))}
            </Select>
            <InputLabel htmlFor={"cast"}>キャスト</InputLabel>
            <Autocomplete
              fullWidth
              classes={highlightNgGuestClasses}
              options={filterCastNames}
              value={selectCastName || null}
              style={{ width: "200px" }}
              getOptionLabel={(option: any) => option.name || ""}
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, value) => {
                setSelectCastName(value || undefined);
                onChangePrice("castNameId")(value?.castNameId || null);
                if (value?.castNameId !== formData?.castNameId) {
                  onChangePrice("options")([]);
                  onChangePrice("optionFee")(0);
                  onChangePrice("optionShopFee")(0);
                  onChangePrice("optionCastFee")(0);
                }
              }}
              getOptionSelected={(option, value) =>
                option.castNameId === value.castNameId
              }
              noOptionsText="選択肢がありません"
            />
            <Tooltip title={castNameInfo} arrow>
              <Info />
            </Tooltip>
            <InputLabel htmlFor={"class"}>クラス</InputLabel>
            <Select
              inputProps={{
                name: "class",
                id: "class",
              }}
              style={{ width: 100 }}
              value={formData?.notelClassId || ""}
              onChange={onChange("notelClassId")}
            >
              <option aria-label="None" value="" />
              {filterNotelClasses.map((notelClass) => (
                <MenuItem
                  value={notelClass.notelClassId}
                  key={notelClass.notelClassId}
                >
                  {notelClass.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display="flex" style={{ margin: 10 }} alignItems="center">
            <InputLabel htmlFor={"time"}>時間</InputLabel>
            <Select
              inputProps={{
                name: "time",
                id: "time",
              }}
              style={{ width: 100 }}
              value={formData?.courseTime || ""}
              onChange={(event) => {
                onChange("courseTime")(event);
                if (!event.target.value && formData?.courseId) {
                  onChangePrice("courseId")("");
                }
              }}
            >
              <option aria-label="None" value="" />
              {filterCourses
                .filter(
                  (element, index, self) =>
                    self.findIndex((e) => e.time === element.time) === index
                )
                .sort((a, b) => (a.time > b.time ? 1 : -1))
                .map((course) => (
                  <MenuItem value={course.time} key={course.time}>
                    {course.time}分
                  </MenuItem>
                ))}
            </Select>
            <InputLabel htmlFor={"nomination"}>指名</InputLabel>
            <Select
              inputProps={{
                name: "nomination",
                id: "nomination",
              }}
              style={{ width: 100 }}
              value={formData?.nominationId || ""}
              onChange={onChange("nominationId")}
            >
              <option aria-label="None" value="" />
              {nominations.map((nomination) => (
                <MenuItem
                  value={nomination.nominationId}
                  key={nomination.nominationId}
                >
                  {nomination.name}
                </MenuItem>
              ))}
            </Select>
            <InputLabel htmlFor={"course"}>コース</InputLabel>
            <Select
              inputProps={{
                name: "course",
                id: "course",
              }}
              style={{ width: 100 }}
              value={formData?.courseId || ""}
              onChange={(event) => {
                onChange("courseId")(event);
                if (!event.target.value && formData?.courseTime) {
                  onChangePrice("courseTime")("");
                }
              }}
            >
              <option aria-label="None" value="" />
              {filterCourses.map((course) => (
                <MenuItem value={course.courseId} key={course.courseId}>
                  {course.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display="flex" style={{ margin: 10 }} alignItems={"flex-start"}>
            <Box display={"flex"} alignItems={"flex-start"}>
              <Box display={"flex"} flexDirection={"column"}>
                {formData?.additionalTimeOrders?.map(
                  (selectAdditionalTimeOrder: any, i: number) => (
                    <Box
                      display={"flex"}
                      key={i}
                      alignItems={"center"}
                      marginTop={i > 0 ? 1 : 0}
                    >
                      {i === 0 && (
                        <InputLabel htmlFor={"additionalTime"}>延長</InputLabel>
                      )}
                      <Select
                        inputProps={{
                          name: "additionalTime",
                          id: "additionalTime",
                        }}
                        style={{
                          width: 100,
                          marginLeft: i > 0 ? "28px" : "initial",
                        }}
                        value={selectAdditionalTimeOrder.additionalTimeId || ""}
                        onChange={(event) =>
                          onChangeAdditionalTimeOrder(
                            i,
                            "additionalTimeId",
                            event.target.value
                          )
                        }
                      >
                        <option aria-label="None" value="" />
                        {filterAdditionalTimes.map((additionalTime) => (
                          <MenuItem
                            value={additionalTime.additionalTimeId}
                            key={additionalTime.additionalTimeId}
                          >
                            {additionalTime.time}分
                            {additionalTime.name && `(${additionalTime.name})`}
                          </MenuItem>
                        ))}
                      </Select>
                      <p style={{ margin: "0 5px" }}>×</p>
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ minWidth: "50px" }}
                        value={selectAdditionalTimeOrder.count || 0}
                        onChange={(event) =>
                          onChangeAdditionalTimeOrder(
                            i,
                            "count",
                            event.target.value
                          )
                        }
                      />
                      <p style={{ margin: "0 0 0 5px" }}>回</p>
                      {i === 0 ? (
                        <Icon
                          onClick={() => {
                            addAdditionalTimeOrder();
                          }}
                        >
                          add
                        </Icon>
                      ) : (
                        <Icon
                          onClick={() => {
                            deleteAdditionalTimeOrder(i);
                          }}
                        >
                          delete
                        </Icon>
                      )}
                    </Box>
                  )
                )}
              </Box>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
              <InputLabel htmlFor={"discount"}>割引</InputLabel>
              <Select
                inputProps={{
                  name: "discount",
                  id: "discount",
                }}
                multiple
                style={{ width: 100 }}
                value={formData?.discounts || []}
                renderValue={(selected) =>
                  filterDiscounts
                    .filter((n) =>
                      (selected as number[]).includes(n.discountId)
                    )
                    .map((n) => n.name)
                    .join(", ")
                }
                onChange={(
                  event: ChangeEvent<{
                    name?: string | undefined;
                    value: unknown;
                  }>
                ) => {
                  onChange("discounts")(event);
                  const discount = discounts.filter((discount) =>
                    (event.target.value as number[]).includes(
                      discount.discountId
                    )
                  );
                  onChangePrice("discountFee")(
                    discount?.reduce((o, b) => b.totalFee + o, 0) || 0
                  );
                  onChangePrice("discountShopFee")(
                    discount?.reduce((o, b) => b.shopFee + o, 0) || 0
                  );
                  onChangePrice("discountCastFee")(
                    discount?.reduce((o, b) => b.castFee + o, 0) || 0
                  );
                }}
              >
                {filterDiscounts.map((discount) => (
                  <MenuItem
                    value={discount.discountId}
                    key={discount.discountId}
                  >
                    <Checkbox
                      checked={
                        formData?.discounts?.indexOf(discount.discountId) > -1
                      }
                    />
                    <ListItemText primary={discount.name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
              <InputLabel htmlFor={"media"}>媒体</InputLabel>
              <Select
                inputProps={{
                  name: "media",
                  id: "media",
                }}
                style={{ width: 100 }}
                value={formData?.mediumId || ""}
                onChange={onChange("mediumId")}
              >
                <option aria-label="None" value="" />
                {filterMedia.map((medium) => (
                  <MenuItem value={medium.mediumId} key={medium.mediumId}>
                    {medium.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Box display="flex" style={{ margin: 10 }} alignItems="center">
            <InputLabel htmlFor={"option"}>オプション</InputLabel>
            <Select
              inputProps={{
                name: "option",
                id: "option",
              }}
              style={{ width: 100 }}
              value={formData?.options || []}
              multiple
              renderValue={(selected) =>
                filterOptions
                  .filter((n) => (selected as number[]).includes(n.optionId))
                  .map((n) => n.name)
                  .join(", ")
              }
              onChange={(
                event: ChangeEvent<{
                  name?: string | undefined;
                  value: unknown;
                }>
              ) => {
                onChange("options")(event);
                const option = options.filter((option) =>
                  (event.target.value as number[]).includes(option.optionId)
                );
                onChangePrice("optionFee")(
                  option?.reduce((o, b) => b.totalFee + o, 0) || 0
                );
                onChangePrice("optionShopFee")(
                  option?.reduce((o, b) => b.shopFee + o, 0) || 0
                );
                onChangePrice("optionCastFee")(
                  option?.reduce((o, b) => b.castFee + o, 0) || 0
                );
              }}
            >
              {filterOptions.map((option) => (
                <MenuItem value={option.optionId} key={option.optionId}>
                  <Checkbox
                    checked={formData?.options?.indexOf(option.optionId) > -1}
                  />
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
            <InputLabel htmlFor={"cosplay"}>コスプレ</InputLabel>
            <Select
              inputProps={{
                name: "cosplay",
                id: "cosplay",
              }}
              style={{ width: 100 }}
              value={formData?.cosplayId || ""}
              onChange={(
                event: ChangeEvent<{
                  name?: string | undefined;
                  value: unknown;
                }>
              ) => {
                onChange("cosplayId")(event);
                const cosplay = cosplays.find(
                  (cosplay) =>
                    cosplay.cosplayId === (event.target.value as number)
                );
                onChangePrice("cosplayFee")(cosplay?.totalFee || 0);
                onChangePrice("cosplayShopFee")(cosplay?.shopFee || 0);
                onChangePrice("cosplayCastFee")(cosplay?.castFee || 0);
              }}
            >
              <option aria-label="None" value="" />
              {filterCosplays.map((cosplay) => (
                <MenuItem value={cosplay.cosplayId} key={cosplay.cosplayId}>
                  {cosplay.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display="flex" style={{ margin: 10 }} alignItems="center">
            <InputLabel htmlFor={"area"}>エリア</InputLabel>
            <Autocomplete
              fullWidth
              classes={highlightNgAreaClasses}
              options={filterAreas}
              value={selectArea || null}
              style={{ width: "200px" }}
              getOptionLabel={(option: any) => option.name || ""}
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, value) => {
                setSelectArea(value || undefined);
                onChangePrice("areaId")(value?.areaId);
                onChangePrice("areaFee")(value?.totalFee || 0);
                onChangePrice("areaShopFee")(value?.shopFee || 0);
                onChangePrice("areaCastFee")(value?.castFee || 0);
              }}
              noOptionsText="選択肢がありません"
            />
            <InputLabel htmlFor={"hotel"}>ホテル</InputLabel>
            <Autocomplete
              fullWidth
              options={filterHotels}
              value={selectHotel || null}
              style={{ width: "200px" }}
              getOptionLabel={(option: any) => option.name || ""}
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, value) => {
                setSelectHotel(value || undefined);
                onChangePrice("hotelId")(value?.hotelId);
              }}
              noOptionsText="選択肢がありません"
            />
          </Box>
          <Box display="flex" style={{ margin: 10 }}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              style={{ margin: 10 }}
            >
              <TextField
                label="住所"
                size="small"
                variant="outlined"
                style={{ width: 500, marginBottom: 10 }}
                value={formData?.orderAddress || ""}
                onChange={onChange("orderAddress")}
              />
              <TextField
                label="住所2"
                size="small"
                variant="outlined"
                style={{ width: 500 }}
                value={formData?.orderAddress2 || ""}
                onChange={onChange("orderAddress2")}
              />
            </Box>
          </Box>
          <Box display="flex" style={{ margin: 10 }}>
            <Box display="flex" alignItems="center" marginX={1}>
              <InputLabel>出発</InputLabel>
              <Box display="flex" marginTop={1}>
                <Select
                  native
                  style={{ minWidth: "50px" }}
                  value={formData?.departureHour || ""}
                  onChange={(event) => onChange("departureHour")(event)}
                >
                  <option value="" />
                  {hours.map((hour: string) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </Select>
                ：
                <Select
                  native
                  style={{ minWidth: "50px" }}
                  value={formData?.departureMinute || ""}
                  onChange={(event) => onChange("departureMinute")(event)}
                >
                  <option value="" />
                  {minutes.map((minute: string) => (
                    <option key={minute} value={minute}>
                      {minute}
                    </option>
                  ))}
                </Select>
              </Box>
            </Box>
            <Box display="flex" marginX={1} alignItems="center">
              <InputLabel>予定IN</InputLabel>
              <Box display="flex" marginTop={1} flex={1}>
                <Select
                  native
                  style={{ minWidth: "50px" }}
                  value={formData?.planInHour || ""}
                  onChange={(event) => onChange("planInHour")(event)}
                >
                  <option value="" />
                  {hours.map((hour: string) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </Select>
                ：
                <Select
                  native
                  style={{ minWidth: "50px" }}
                  value={formData?.planInMinute || ""}
                  onChange={(event) => onChange("planInMinute")(event)}
                >
                  <option value="" />
                  {minutes.map((minute: string) => (
                    <option key={minute} value={minute}>
                      {minute}
                    </option>
                  ))}
                </Select>
              </Box>
            </Box>
            <KeyboardDateTimePicker
              label="予定OUT"
              size="small"
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formData?.planOutTime || null}
              format="YYYY/MM/DD HH:mm"
              disabled
              onChange={(date) =>
                onChangePrice("planOutTime")(date?.toDate() || null)
              }
              minutesStep={5}
              variant="inline"
            />
          </Box>
          <Box display="flex" style={{ margin: 10 }} alignItems="center">
            <Box display="flex" alignItems="center" marginX={1}>
              <InputLabel>実IN</InputLabel>
              <Box display="flex" marginTop={1}>
                <Select
                  native
                  value={
                    formData?.actualInHour ||
                    formData?.requestActualInHour ||
                    ""
                  }
                  onChange={(event) =>
                    !formData?.actualInHour && formData?.requestActualInHour
                      ? onChange("requestActualInHour")(event)
                      : onChange("actualInHour")(event)
                  }
                  style={{
                    minWidth: "50px",
                    color:
                      !formData?.actualInHour && formData?.requestActualInHour
                        ? "red"
                        : "inherit",
                  }}
                >
                  <option value="" />
                  {hours.map((hour: string) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </Select>
                ：
                <Select
                  native
                  value={
                    formData?.actualInMinute ||
                    formData?.requestActualInMinute ||
                    ""
                  }
                  onChange={(event) =>
                    !formData?.actualInMinute && formData.requestActualInMinute
                      ? onChange("requestActualInMinute")(event)
                      : onChange("actualInMinute")(event)
                  }
                  style={{
                    minWidth: "50px",
                    color:
                      !formData?.actualInMinute &&
                      formData?.requestActualInMinute
                        ? "red"
                        : "inherit",
                  }}
                >
                  <option value="" />
                  {Array.from(Array(60).keys())
                    .map((number) => String(number).padStart(2, "0"))
                    .map((minute: string) => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))}
                </Select>
              </Box>
            </Box>
            <TextField
              label="実OUT"
              type="datetime-local"
              variant="outlined"
              size="small"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                style: {
                  color: formData?.requestActualEndTime ? "red" : "inherit",
                },
              }}
              value={
                formData?.requestActualEndTime || formData?.actualEndTime
                  ? DateTimeUtils.toFormatAsLocalTimezone(
                      formData?.requestActualEndTime || formData?.actualEndTime,
                      "yyyy-MM-dd'T'HH:mm"
                    )
                  : ""
              }
            />
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={onClickAcceptCastRequestButton}
              disabled={
                !formData.requestActualEndTime && !formData.requestActualInTime
              }
            >
              承認
            </Button>
          </Box>
          <Box display="flex" style={{ margin: 10 }} alignItems="center">
            <InputLabel htmlFor={"responseStatus"}>応答</InputLabel>
            <Select
              inputProps={{
                name: "responseStatus",
                id: "responseStatus",
              }}
              style={{ width: 100 }}
              value={formData["responseStatus"] || ""}
              onChange={onChange("responseStatus")}
            >
              <option aria-label="None" value="" />
              {Object.entries(OrderResponseStatus).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(formData["collectReceivable"])}
                  onChange={onChangeCheckBox("collectReceivable")}
                  disabled
                />
              }
              label="回収"
            />
            <InputLabel htmlFor={"paymentType"}>支払い</InputLabel>
            <Select
              inputProps={{
                name: "paymentType",
                id: "paymentType",
              }}
              style={{ width: 100 }}
              onChange={onChange("paymentType")}
              value={formData["paymentType"] || "cash"}
            >
              <option aria-label="None" value="" />
              {Object.entries(PaymentType).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display="flex" alignItems={"flex-start"} style={{ margin: 10 }}>
            <TextField
              multiline
              label="メモ"
              variant="outlined"
              style={{ margin: 5 }}
              rows={3}
              rowsMax={1000}
              value={formData?.memo}
              onChange={onChange("memo")}
            />
            <TextField
              multiline
              label="アンケート"
              variant="outlined"
              style={{ margin: 5 }}
              rows={3}
              rowsMax={1000}
              value={formData?.questionnaire}
              onChange={onChange("questionnaire")}
            />
            <TextField
              label="評価点"
              type="number"
              size="small"
              variant="outlined"
              style={{ margin: 5 }}
              value={formData?.score}
              onChange={(event) =>
                onChangePrice("score")(Number(event.target.value))
              }
            />
            /100点
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Button
              variant={"contained"}
              color={"secondary"}
              onClick={onClickResetOrderInfoButton}
            >
              リセット
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OrderInfo;
