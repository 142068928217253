import { DataValidationError } from "constants/DataValidationError";
import { IsNotEmpty } from "class-validator";

export default class BulkSendMarketingMailReq {
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  emails!: string[];

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  template!: string;

  constructor(arg: { emails: string[]; template: string }) {
    arg = arg || {};
    this.emails = arg.emails;
    this.template = arg.template;
  }
}
