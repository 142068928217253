import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Main from "layouts/Main";
import Landing from "pages/Landing";
import Company from "pages/Company";
import Page404 from "pages/Page404";
import Staff from "pages/Staff";
import Shop from "pages/Shop";
import Cast from "pages/Cast";
import Signin from "pages/Signin";
import { useSelector } from "react-redux";
import Driver from "pages/Driver";
import Course from "pages/Course";
import Discount from "pages/Discount";
import Cosplay from "pages/Cosplay";
import Option from "pages/Option";
import Area from "pages/Area";
import Tabs from "components/Tabs";
import StaffTask from "../pages/StaffTask";
import MessageBoard from "../pages/MessageBoard";
import CastName from "../pages/CastName";
import CastShift from "../pages/CastShift";
import DriverShift from "../pages/DriverShift";
import GuestCategory from "../pages/GuestCategory";
import Order from "../pages/Order";
import Guest from "../pages/Guest";
import Hotel from "../pages/Hotel";
import DriverSchedule from "../pages/DriverSchedule";
import AdditionalTime from "../pages/AdditionalTime";
import Medium from "../pages/Medium";
import SettingBookEmail from "../pages/SettingBookEmail";
import OrderList from "../pages/OrderList";
import Setting from "../pages/Setting";
import Notification from "../pages/Notification";
import Payoff from "../pages/Payoff";
import CastSchedule from "../pages/CastSchedule";
import Nomination from "../pages/Nomination";
import NotelClass from "../pages/NotelClass";
import CastShiftBulkInsert from "../pages/CastShift/bultInsert";
import DriverShiftBulkInsert from "../pages/DriverShift/bulkInsert";
import CastCategory from "../pages/CastCategory";
import Accounting from "../pages/Accounting";
import CompanyGroup from "../pages/CompanyGroup";
import ResetPasswordSend from "pages/ResetPasswordSend";
import ResetPassword from "pages/ResetPassword";
import GuestPointHistory from "pages/GuestPointHistory";
import OrderListByGuest from "pages/OrderListByGuest";
import CallHistory from "pages/CallHistory";
import CallHistoryGraph from "pages/CallHistoryGraph";
import Analytics from "pages/Analytics";
import CastAnalyticsRanking from "pages/CastAnalyticsRanking";
import IndividualCastAnalytics from "pages/CastAnalyticsRanking/IndividualCastAnalytics";
import Payment from "pages/Payment";
import GuestAnalyticsSummarySales from "pages/GuestAnalytics/GuestAnalyticsSummarySales";
import DriverSettingBookEmail from "pages/DriverSettingBookEmail";
import GuestAnalyticsDetail from "pages/GuestAnalytics/Detail";
import GuestAnalytics from "pages/GuestAnalytics";
import GuestAnalyticsProfileSales from "pages/GuestAnalytics/GuestAnalyticsProfileSales";
import GuestAnalyticsAreaSales from "pages/GuestAnalytics/GuestAnalyticsAreaSales";
import GuestAnalyticsSalesVolume from "pages/GuestAnalytics/GuestAnalyticsSalesVolume";
import OrderListByCast from "pages/OrderListByCast";
import GuestAnalyticsCpmAnalytics from "pages/GuestAnalytics/GuestAnalyticsCpmAnalytics";
import GuestAnalyticsMediaLtv from "pages/GuestAnalytics/GuestAnalyticsMediaLtv";
import GuestAnalyticsGuestGroupLtv from "pages/GuestAnalytics/GuestAnalyticsGuestGroupLtv";
import SpMain from "layouts/SpMain";
import SpSignin from "spPages/Signin";
import SpResetPassword from "spPages/ResetPassword";
import SpResetPasswordSend from "spPages/ResetPasswordSend";
import SpLanding from "spPages/Landing";
import SpCastSchedule from "spPages/CastSchedule";
import SpCastShift from "spPages/CastShift";
import SpDriverShift from "spPages/DriverShift";
import SpDriverSchedule from "spPages/DriverSchedule";
import SpPayoff from "spPages/Payoff";
import SpOrder from "spPages/Order";
import SpOrderList from "spPages/OrderList";
import Maintenance from "pages/Maintenance";
import SpCompanySwitch from "spPages/CompanySwitch";
import SiteSwitch from "spPages/SiteSwitch";
import OrderBulkImportCheck from "pages/OrderBulkImportCheck";
import usePageTracking from "hooks/usePageTracking";
import RequestGuest from "pages/RequestGuest";
import CastMobileMenu from "pages/CastMobileMenu";
import GuestNote from "pages/GuestNote";
import RecruitingMedia from "pages/RecruitingMedia";
import GuestAnalyticsRecruitingMediaLtv from "pages/GuestAnalytics/GuestAnalyticsRecruitingMediaLtv";
import MarketingMail from "pages/MarketingMail";

type RouteType = {
  path: string;
  component: React.FC<any>;
  exact?: boolean;
};

type Routes = {
  [key: string]: RouteType;
};

const authorizedRoutes: Routes = {
  landing: {
    path: "/",
    exact: true,
    component: Landing,
  },
  companyGroup: {
    path: "/companyGroup",
    component: CompanyGroup,
  },
  company: {
    path: "/company",
    component: Company,
  },
  staff: {
    path: "/staff",
    component: Staff,
  },
  cast: {
    path: "/cast",
    component: Cast,
  },
  driver: {
    path: "/driver",
    component: Driver,
  },
  course: {
    path: "/course",
    component: Course,
  },
  discount: {
    path: "/discount",
    component: Discount,
  },
  cosplay: {
    path: "/cosplay",
    component: Cosplay,
  },
  shop: {
    path: "/shop",
    component: Shop,
  },
  option: {
    path: "/option",
    component: Option,
  },
  area: {
    path: "/area",
    component: Area,
  },
  staffTask: {
    path: "/staffTask",
    component: StaffTask,
  },
  medium: {
    path: "/medium",
    component: Medium,
  },
  messageBoard: {
    path: "/messageBoard",
    component: MessageBoard,
  },
  castName: {
    path: "/castName",
    component: CastName,
  },
  castShiftBulkInsert: {
    path: "/castShift/bulkInsert",
    component: CastShiftBulkInsert,
  },
  castShift: {
    path: "/castShift",
    component: CastShift,
  },
  castSchedule: {
    path: "/castSchedule",
    component: CastSchedule,
  },
  castCategory: {
    path: "/castCategory",
    component: CastCategory,
  },
  driverShiftBulkInsert: {
    path: "/driverShift/bulkInsert",
    component: DriverShiftBulkInsert,
  },
  driverShift: {
    path: "/driverShift",
    component: DriverShift,
  },
  guestPoint: {
    path: "/guestPoint",
    component: GuestPointHistory,
  },
  guestCategory: {
    path: "/guestCategory",
    component: GuestCategory,
  },
  editOrder: {
    path: "/order/:orderId",
    component: Order,
  },
  newOrder: {
    path: "/order",
    component: Order,
  },
  orderListByGuest: {
    path: "/orderList/guest/:guestId",
    component: OrderListByGuest,
  },
  orderListByCast: {
    path: "/orderList/cast/:castId",
    component: OrderListByCast,
  },
  orderList: {
    path: "/orderList",
    component: OrderList,
  },
  guest: {
    path: "/guest",
    component: Guest,
  },
  hotel: {
    path: "/hotel",
    component: Hotel,
  },
  driverSchedule: {
    path: "/driverSchedule",
    component: DriverSchedule,
  },
  additionalTime: {
    path: "/additionalTime",
    component: AdditionalTime,
  },
  settingBookEmail: {
    path: "/settingBookEmail",
    component: SettingBookEmail,
  },
  notification: {
    path: "/notification",
    component: Notification,
  },
  payoff: {
    path: "/payoff",
    component: Payoff,
  },
  nomination: {
    path: "/nomination",
    component: Nomination,
  },
  class: {
    path: "/class",
    component: NotelClass,
  },
  setting: {
    path: "/setting",
    component: Setting,
  },
  accounting: {
    path: "/accounting",
    component: Accounting,
  },
  callHistoryGraph: {
    path: "/callHistory/graph",
    component: CallHistoryGraph,
  },
  callHistory: {
    path: "/callHistory",
    component: CallHistory,
  },
  analytics: {
    path: "/analytics",
    component: Analytics,
  },
  individualCastAnalytics: {
    path: "/castAnalyticsRanking/cast/:castId",
    component: IndividualCastAnalytics,
  },
  castAnalytics: {
    path: "/castAnalyticsRanking",
    component: CastAnalyticsRanking,
  },
  payment: {
    path: "/payment",
    component: Payment,
  },
  guestAnalyticsRecruitingMediaLtv: {
    path: "/guestAnalytics/recruitingMediaLtv",
    component: GuestAnalyticsRecruitingMediaLtv,
  },
  guestAnalyticsCpmAnalytics: {
    path: "/guestAnalytics/cpmAnalytics",
    component: GuestAnalyticsCpmAnalytics,
  },
  guestAnalyticsSalesVolume: {
    path: "/guestAnalytics/salesVolume",
    component: GuestAnalyticsSalesVolume,
  },
  guestAnalyticsSummarySales: {
    path: "/guestAnalytics/summarySales",
    component: GuestAnalyticsSummarySales,
  },
  guestAnalyticsProfileSales: {
    path: "/guestAnalytics/profileSales",
    component: GuestAnalyticsProfileSales,
  },
  guestAnalyticsAreaSales: {
    path: "/guestAnalytics/areaSales",
    component: GuestAnalyticsAreaSales,
  },
  guestAnalyticsDetail: {
    path: "/guestAnalytics/detail",
    component: GuestAnalyticsDetail,
  },
  guestAnalyticsMediaLtv: {
    path: "/guestAnalytics/mediaLtv",
    component: GuestAnalyticsMediaLtv,
  },
  guestAnalyticsGuestGroupLtv: {
    path: "/guestAnalytics/guestGroupLtv",
    component: GuestAnalyticsGuestGroupLtv,
  },
  guestAnalytics: {
    path: "/guestAnalytics",
    component: GuestAnalytics,
  },
  driverSettingBookEmail: {
    path: "/driverSettingBookEmail",
    component: DriverSettingBookEmail,
  },
  orderBulkImportCheck: {
    path: "/orderBulkImportCheck",
    component: OrderBulkImportCheck,
  },
  requestGuest: {
    path: "/requestGuest",
    component: RequestGuest,
  },
  guestNote: {
    path: "/guestNote",
    component: GuestNote,
  },
  recruitingMedia: {
    path: "/recruitingMedia",
    component: RecruitingMedia,
  },
  marketingMail: {
    path: "/marketingMail",
    component: MarketingMail,
  },
};

const unauthorizedRoutes: Routes = {
  ResetPasswordSend: {
    path: "/resetPassword/send",
    component: ResetPasswordSend,
  },
  ResetPassword: {
    path: "/resetPassword",
    component: ResetPassword,
  },
  signin: {
    path: "/login",
    component: Signin,
  },
};

const spAuthorizedRoutes: Routes = {
  landing: {
    path: "/",
    exact: true,
    component: SpLanding,
  },
  castSchedule: {
    path: "/castSchedule",
    component: SpCastSchedule,
  },
  castShift: {
    path: "/castShift",
    component: SpCastShift,
  },
  driverShift: {
    path: "/driverShift",
    component: SpDriverShift,
  },
  driverSchedule: {
    path: "/driverSchedule",
    component: SpDriverSchedule,
  },
  payoff: {
    path: "/payoff",
    component: SpPayoff,
  },
  editOrder: {
    path: "/order/:orderId",
    component: SpOrder,
  },
  newOrder: {
    path: "/order",
    component: SpOrder,
  },
  orderList: {
    path: "/orderList",
    component: SpOrderList,
  },
  companySwitch: {
    path: "/companySwitch",
    component: SpCompanySwitch,
  },
  siteSwitch: {
    path: "/siteSwitch",
    component: SiteSwitch,
  },
  castMobileMenu: {
    path: "/castMobileMenu",
    component: CastMobileMenu,
  },
};

const spUnauthorizedRoutes: Routes = {
  ResetPasswordSend: {
    path: "/resetPassword/send",
    component: SpResetPasswordSend,
  },
  ResetPassword: {
    path: "/resetPassword",
    component: SpResetPassword,
  },
  signin: {
    path: "/login",
    component: SpSignin,
  },
};
const configureRoutes = (
  Layout: React.FC,
  routes: Routes,
  redirect?: string,
  authorized = true
) => {
  const components = Object.values(routes).map(
    ({ component: Component, path, exact = false }, index) => (
      <Route
        key={index}
        path={path}
        exact={exact}
        render={(props) => (
          <Layout>
            {authorized && <Tabs />}
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

  if (redirect) {
    components.push(<Redirect key="redirect" to={redirect} />);
  }

  return components;
};

const spConfigureRoutes = (
  Layout: React.FC,
  routes: Routes,
  redirect?: string
) => {
  const components = Object.values(routes).map(
    ({ component: Component, path, exact = false }, index) => (
      <Route
        key={index}
        path={path}
        exact={exact}
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

  if (redirect) {
    components.push(<Redirect key="redirect" to={redirect} />);
  }

  return components;
};

const Auth: React.FC = ({ children }) => {
  const isLoggedin = useSelector((state) => Boolean(state.account.accessToken));
  usePageTracking();

  return (
    <Switch>
      {isLoggedin
        ? children
        : configureRoutes(Main, unauthorizedRoutes, "/login", false)}
    </Switch>
  );
};

const SpAuth: React.FC = ({ children }) => {
  const isLoggedin = useSelector((state) => Boolean(state.account.accessToken));
  usePageTracking();

  return (
    <Switch>
      {isLoggedin
        ? children
        : configureRoutes(SpMain, spUnauthorizedRoutes, "/login", false)}
    </Switch>
  );
};

const Routes: React.FC = () => {
  const spQuery = matchMedia("(max-width: 768px)");
  const staff = useSelector((state) => state.account.staff);
  if (process.env.REACT_APP_MAINTENANCE_MODE === "ON") {
    return <Maintenance />;
  }

  if (staff?.isForceSite === "pc") {
    return (
      <Router>
        <Switch>
          <Auth>{configureRoutes(Main, authorizedRoutes, "/")}</Auth>
          <Route render={() => <Page404 />} />
        </Switch>
      </Router>
    );
  }
  if (staff?.isForceSite === "sp") {
    return (
      <Router>
        <Switch>
          <SpAuth>{spConfigureRoutes(SpMain, spAuthorizedRoutes, "/")}</SpAuth>
          <Route render={() => <Page404 />} />
        </Switch>
      </Router>
    );
  }
  return (
    <Router>
      {spQuery.matches ? (
        <Switch>
          <SpAuth>{spConfigureRoutes(SpMain, spAuthorizedRoutes, "/")}</SpAuth>
          <Route render={() => <Page404 />} />
        </Switch>
      ) : (
        <Switch>
          <Auth>{configureRoutes(Main, authorizedRoutes, "/")}</Auth>
          <Route render={() => <Page404 />} />
        </Switch>
      )}
    </Router>
  );
};

export default Routes;
