import BulkSendMarketingMailReq from "types/req/marketingMail/BulkSendMarketingMailReq";
import MarketingMailRes from "types/res/marketingMail/MarketingMailRes";
import RequestUtils from "utils/RequestUtils";
export default class MarketingMailApi {
  private constructor() {}
  static async findAll(month: string): Promise<MarketingMailRes[]> {
    return await RequestUtils.getArray(
      MarketingMailRes,
      `/sendMarketingMail/findAll?month=${month}`
    );
  }

  static async bulkSend(data: BulkSendMarketingMailReq) {
    return await RequestUtils.postVoid(`/sendMarketingMail/create`, data);
  }
}
